.enq{
    text-orientation: sideways-right;
    -webkit-text-orientation: sideways-right;
    writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
}

.close{
    padding-left: 0px !important;
}