.cert::after{
    content: "";
    width: 100px;
    height: 8px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) ;
    -webkit-transform: translateX(-50%) ;
    -moz-transform: translateX(-50%) ;
    -ms-transform: translateX(-50%) ;
    -o-transform: translateX(-50%) ;
    background-color: #262476;
}
.cusArr::before{
    display: none !important;
}
.certBG{
    background-image: url('../../assets/images/trophy.png');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 25%;
}
.cert-img{
    border: 4px solid #262476;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cert-img:hover{
    transform: scale(1.1);
}

#cert-carousel-prev{
    left: -4% !important;
}

.cert-close-btn{
    position: absolute;
    z-index: 2;
    right: 0;
    margin: 10px;
}

.certificate-modal-body{
    width: 400px !important;
}


@media only screen and (max-width: 600px) {
    .slick-prev {
        left: -10px !important;
    }
    .slick-next {
        right: -10px !important;
    }
    .certificate-modal-body{
        width: 360px !important;
    }
}

