.galleyTab ul {
  border-bottom: none;
  text-align: center;
  background-color: var(--gray);
  max-width: 800px;
  margin: 0 auto;
  display: flex;
}
.galleyTab ul li {
  color: white;
  font-family: 'Cairo';
  font-weight: bold;
  border: none;
  bottom: 0;
  padding: 15px;
  box-shadow: none !important;
  outline: none !important;
  position: relative;
}
.galleyTab ul li::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
}
.galleyTab ul li:last-child::after {
    display: none;
}
.galleyTab ul .react-tabs__tab--selected {
  color: white;
  background-color: var(--darkBlue);
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.galleyTab ul li:nth-child(1) {
    flex: 0 0 23.3%;
}
.galleyTab ul li:nth-child(2) {
    flex: 0 0 23.3%;
}
.galleyTab ul li:nth-child(3) {
    flex: 0 0 30%;
}
.galleyTab ul li:nth-child(4) {
    flex: 0 0 23.3%;
}

.modal-dialog {
  max-width: 1050px !important;
}

.gallery-carousel>.carousel-control-prev{
  left: 0% !important;
  background-color: transparent !important;
}

.gallery-carousel>.carousel-control-next{
  left: 91% !important;
  background-color: transparent !important;
}


@media only screen and (max-width: 600px) {
  .gallery-logo{
    display: none;
  }
  .vertical-center{
    transform: translateY(50%);
  }
  .galleyTab ul li {
    padding: 10px;
  }
  .iphone-height{
    height: auto;
  }
}
