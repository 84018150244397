.productBtn{
  position: relative;
  transition: all 0.5s ;
  -webkit-transition: all 0.5s ;
  -moz-transition: all 0.5s ;
  -ms-transition: all 0.5s ;
  -o-transition: all 0.5s ;
  z-index: 2;
}
.productBtn:hover.productBtn::before{
  width: 150px;
  opacity: 1;
}
.productBtn::before{
    content: "";
    position: absolute;
    width: 150px;
    height: 100%;
    background: transparent linear-gradient(90deg, #7fc4fd 0%, #262476 100%) 0% 0%    no-repeat padding-box;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.prod-white{
  color: #fff;
}

.accordHead .MuiAccordionSummary-content.Mui-expanded {
  border-bottom: 1px solid #fff;
}
.MuiAccordion-root.Mui-expanded:first-child {
  margin-bottom: 0px;
}
.product-active{
  color: var(--lightYellow) !important;
}
.accordHead .Mui-expanded span a {
  color: var(--lightYellow) !important;
}
.MuiAccordionDetails-root a:hover{
  color: var(--lightYellow) !important;
}
.MuiAccordionDetails-root a:focus{
  color: var(--lightYellow) !important;
}
.productList:last-child {
  border: none;
}
.productArr {
  display: block !important;
  bottom: -80px !important;
  top: inherit !important;
  width: 2rem !important;
}

.productPrev{
  left: 30% !important;
}
.productNext{
  left: 50% !important;
}

.features-line:last-child{
  display: none;
}

.padding-x16{
  padding: 0px 16px;
}


@media screen and (min-width: 787px) {
  .prodFeatures-mobile{
    display: none;
  }
  .filterpress3-mobile{
    display: none;
  }
}

@media screen and (max-width: 786px) {
  .prodFeatures-desktop{
    display: none;
  }
  .filterpress3-desktop{
    display: none;
  }
  .padding-x16{
    padding: 0px;
  }
  .chart-px5{
    padding: 0px 0px 0px 15px !important;
  }
  .font-10{
    font-size: 9px;
    line-height: 1;
  }
  .pump-ml{
    margin-left: 4px;
  }
}