.gallery-modal-img{
    height: auto;
    width: auto;
}

.gallery-modal-img img{
    position: relative !important;
    text-align: center !important;
    margin: 0 auto !important;
    top: 50%;
    transform: translateY(-50%);
}

#myCarousel3>.carousel-control-prev{
    left: 0% !important;
    background-color: transparent !important;
}

#myCarousel3>.carousel-control-next{
    left: 89% !important;
    background-color: transparent !important;
}

.facility-container{
    padding-top: 48px;
}

.homeGallery-section{
    padding-top: 48px;
}


@media only screen and (max-width: 600px) {
    .facility-container{
        padding-top: 25px;
    }
    .homeGallery-section{
        padding-top: 15px;
    }
}