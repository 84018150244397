.content-text{
    box-shadow: 0 5px 30px rgb(0 0 0 / 15%);
}

.profile-img{
    position: relative;
    display: inline-block;
    height: 100px;
}

.profile-img img{
    width: 70px;
    height: auto;
    border-radius: 50%;
}

.test-quote{
    height: 45px;
    width: 45px;
    background-color: #262476;
    position: absolute;
    right: 30px;
    top: -20px;
}

.test-quote-icon svg{
    color: #fff;
    margin: 15px;
}

.content-text::after{
    content: "";
    display: block;
    width: 22px;
    height: 15px;
    background-color: #fff;
    position: absolute;
    top: -12px;
    left: 80px;
    z-index: 1;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.carousel-control-prev{
    left: -4% !important;
}

.carousel-control-next{
    left: 100% !important;
}

.carousel-control-next, .carousel-control-prev{
    height: 50px !important;
    width: 50px !important;
    background-color: #262476;
    border-radius: 50%;
    top: 45% !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    width: 25px !important;
    height: 25px !important;
}

.test-content{
    padding: 0px 20px 20px;
}

.content-text{
    padding: 24px;
}

.content-text p{
    padding: 8px;
}

.content-profile{
    margin-bottom: 12px;
    padding: 0px 10px;
}

.profile-desc{
    padding: 22px;
}

.certificate-item{
    margin: 24px;
    padding: 28px;
}




@media screen and (min-width: 787px) {
    .testCarousel-mobile{
        display: none;
    }
}
  
@media screen and (max-width: 786px) {
    .testCarousel-desktop{
        display: none;
    }
    .carousel-control-prev{
        left: 0% !important;
    }
    .carousel-control-next{
        left: 90% !important;
    }
    .carousel-control-next, .carousel-control-prev{
        height: 40px !important;
        width: 40px !important;
    }
    .carousel-control-next-icon, .carousel-control-prev-icon{
        width: 18px !important;
        height: 18px !important;
    }
    .test-content{
        padding: 0px 15px 15px;
    }
    .profile-desc {
        padding: 0px 0px 0px 10px;
    }
    .profile-img {
        position: relative;
        display: inline-block;
        height: 85px;
    }
    .content-text::after {
        left: 60px;
    }
    .certificate-item{
        margin: 10px;
        padding: 10px;
    }
}