.close{
    padding-left: 0px !important;
}

.iframe-main-video iframe {
    width: 950px;
    height: 534px;
}

.main-video-modal {
    width: 985px !important;
}

@media screen and (max-width: 600px) {
    .iframe-main-video iframe {
        width: 100%;
        height: auto;
    }

    #AutoPopup-Modal{
        width: 90%;
        padding: 5px;
    }
}