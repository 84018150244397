.aboutHead::after{
    content: "";
    width: 100px;
    height: 7px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) ;
    -webkit-transform: translateX(-50%) ;
    -moz-transform: translateX(-50%) ;
    -ms-transform: translateX(-50%) ;
    -o-transform: translateX(-50%) ;
    background-color: #262476;
}
.core::after{
    content: "";
    width: 100px;
    height: 7px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) ;
    -webkit-transform: translateX(-50%) ;
    -moz-transform: translateX(-50%) ;
    -ms-transform: translateX(-50%) ;
    -o-transform: translateX(-50%) ;
    background-color: #262476;
}
.aboutBg{
    /* background-image: url('../../assets/images/globe.png'); */
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 40%;
    margin-top: 80px;
}

.item img{
    margin: 60px auto;
    transition: transform .5s;
    /* height: 200px; */
}

.slick-center img{
    transform: scale(1.5);
    border: 7px solid #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.homeAbout-slider>.slick-list{
    height: 450px;
}

.homeAbout-slider>.slick-list>.slick-track{
    top: 25px;
}

.video-play-btn {
    position: absolute;
    display: block;
    width: 40%;
    margin: 0 auto;
    top: 15%;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
}

.video-modal{
    width: 595px !important;
}

.homeAbout-establish{
    text-align: center;
    margin-bottom: 16px;
}

.core-content{
    text-align: center;
    padding: 16px;
}

.core-detailBtn{
    font-size: 14px;
    padding: 12px 16px;
}

.margin-b1{
    margin-bottom: 36px;
}

.margin-b2{
    margin-bottom: 36px;
}

.coreProd-section{
    padding: 32px 24px;
}

.knowMore-btn{
    width: 144px;
    margin: 24px auto 48px;
    text-align: center;
}

.knowMore-btn a{
    padding: 8px 0px;
}

#contained-modal-title-vcenter{
    width: 535px !important;
}

.video-section video{
    height: 250px !important;
    width: 560px !important;
}

.iframe-content iframe{
    width: 560px;
    height: 315px;
}



@media only screen and (max-width: 600px) {
    .slick-center img{
        border: 3px solid #fff;
    }
    .homeAbout-slider>.slick-list>.slick-track{
        top: -30px;
    }
    .homeAbout-slider>.slick-list{
        height: 160px;
    }
    .homeAbout-establish{
        text-align: justify;
    }
    .video-play-btn {
        width: 35%;
        top: 13%;
    }
    .core-content{
        padding: 4px;
        height: 90px;
    }
    .core-detailBtn{
        font-size: 10px;
        padding: 8px 0px;
    }
    .margin-b1{
        margin-bottom: 0px;
    }
    .margin-b2{
        margin-bottom: 0px;
    }
    .coreProd-section{
        padding: 15px 15px;
    }
    .knowMore-btn{
        width: 100px;
        margin: 0px auto 32px;
    }
    .knowMore-btn a{
        padding: 6px 0px;
    }
    .video-section video{
        height: 150px !important;
        width: 350px !important;
    }
    #contained-modal-title-vcenter{
        width: 330px !important;
    }
    .font-12{
        font-size: 14px !important;
    }
    .modal-header{
        padding: 5px !important;
    }
    .modal-body{
        padding: 5px !important;
    }
    .modal-content{
        width: auto !important;
    }
    .modal.show .modal-dialog {
        width: fit-content !important;
        margin: 0px auto;
    }
    .video-modal{
        width: 195px !important;
    }
    .iframe-content iframe{
        width: 350px;
        height: 280px;
    }
    .aboutBg{
        margin-top: 150px;
    }
}