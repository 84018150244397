.makeIndiaBg {
  background-image: url("../../assets/images/grind.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.makeIndiaBg::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #382a59d4;
  position: absolute;
  left: 0;
  top: 0;
}
.caseContainer {
  transform: translateY(-20px);
}
.makindiaImageCon {
  transform: translateY(45px);
}

.caseStudyContainerMain{
    width: 30rem;
    margin: 0 60px;
}
.caseContainerMain{
    padding-top: 1rem;
    border-right: 5px solid #7FC4FD;
    border-bottom: 5px solid #7FC4FD;
    border-left: 5px solid #7FC4FD;
}
.caseHead{
    transform: translateY(13px);
    position: relative;
}
.caseHead::after,
.caseHead::before{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #7FC4FD;
    width: 120px;
    height: 5px;
}
.caseHead::before{
    left: 0;
    bottom: 0;
}

.makeIndiaContainer{
  max-width: 1140px !important;
}

.core-detailBtn{
  font-size: 14px;
  padding: 12px 16px;
}


@media screen and (max-width: 600px) {
  .caseStudyContainerMain{
    width: 20rem;
    margin: 0 auto;
  }
  .weServe-text{
    text-align: center;
  }
  .makindiaImageCon {
    transform: translateY(10px);
    margin: 0 auto 15px;
  }
  .core-detailBtn{
    font-size: 10px;
    padding: 8px 0px;
  }
}