.historyPara {
  border-radius: 40px;
  padding: 48px 24px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.circle {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--darkBlue);
  border-radius: 50%;
}
.circle::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #D5D5D5;
  position: absolute;
  z-index: -1;
  bottom: 0;
}
.circleContainer::before {
  content: "";
}

.contentContainer{
    padding-right: 7rem !important;
}

.margin-t48{
  margin-top: 48px;
}

.margin-t32{
  margin-top: 32px;
}


@media only screen and (max-width: 600px) {
  .margin-t48{
    margin-top: 15px;
  }
  .contentContainer{
    padding-right: 15px !important;
  }
  .historyPara {
    padding: 15px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .margin-t32{
    margin-top: 15px;
  }
}