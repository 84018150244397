/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Cairo-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-Bold.woff2') format('woff2'),
      url('./assets/fonts/Cairo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-Regular.woff2') format('woff2'),
      url('./assets/fonts/Cairo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-Black.woff2') format('woff2'),
      url('./assets/fonts/Cairo-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-Light.woff2') format('woff2'),
      url('./assets/fonts/Cairo-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('./assets/fonts/Cairo-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Cairo-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --gray: #5b5a5a;
  --darkBlue: #262476;
  --lightBlue: #7FC4FD;
  --lightYellow: #F9BF00;
}

.font-cario{
  font-family: 'Cairo';
}
.font-poppins{
  font-family: 'Poppins';
}

.no-underline{
  text-decoration: none !important;
}

.cusArrTest{
    display: block !important;
    bottom: -50px !important;
    top: inherit !important;
    left: 0 !important;
    width: 2rem !important;
}
.cusArrNext{
    display: block !important;
    bottom: -50px !important;
    top: inherit !important;
    left: 3rem !important;
    width: 2rem !important;
}
.cusContainer{
  max-width: 1250px !important;
}
input,
textarea{
  outline: none !important;
}
