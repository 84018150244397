.env-1{
  background-image: url("../../assets/images/about/env0.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}
  
.env-2{
  background-image: url("../../assets/images/about/env2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.env-3{
  background-image: url("../../assets/images/about/env4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.env-col-12{
  height: 500px;
  padding: 0 !important;
}

.envContainer{
  margin: 0 !important;
  max-width: 100% !important;
  margin-bottom: -32px !important;
}

.list-icon{
  flex-shrink: 0;
}

.center-logo{
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.center-logo svg{
  position: relative;
  height: 60px;
  width: 60px;
  display: block;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.svg-logo {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  fill: #fff;
  z-index: 1;
  margin: 0 auto;
  position: relative;
}

.env-logo1{
  background-color: #6A961F;
}

.env-logo2{
  background-color: #262476;
}

.env-logo3{
  background-color: #0541A6;
}

.animation-pulse:hover{
  animation: zoom-in-zoom-out 1s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0%   {transform: scale(1,1);}
  50% {transform: scale(1.2,1.2);}
  100% {transform: scale(1,1);}
}



@media screen and (min-width: 787px) {
  .envContainer-mobile{
      display: none;
  }
}

@media screen and (max-width: 786px) {
  .envContainer{
      display: none;
  }
  .env-column-12{
    height: auto;
  }
  .env-1, .env-2, .env-3{
    height: 250px; 
  }
  .envContainer-mobile{
    margin-bottom: -24px !important;
  }
  .center-logo{
    left: 0;
    top: 100%;
  }
  /* .center-logo1{
    top: 31%;
  }
  .center-logo2{
    top: 33%;
  }
  .center-logo3{
    top: 32%;
  } */
  .center-logo svg{
    height: 50px;
    width: 50px;
  }
  .svg-logo {
    height: 80px;
    width: 80px;
  }
}


