.clientBG{
    background-image: url("../../assets/images/map.png");
    background-repeat: repeat-y;
    background-position: center;
    background-size: 100%;
}

.achiveImage{
    width: 4rem;
    margin: 0 auto;
}
.center{
    margin: 0 auto;
}

.clientContainer{
    max-width: 1140px !important;
    padding-top: 48px;
}

.install-padding{
    padding: 0px;
}

.home-clientsContainer{
    padding: 30px;
}

.home-clients-img{
    height: 120px;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.home-clients-img img{
    width: 60%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

#home-client-width{
    width: 45%;
}

#home-client-width2{
    width: 90%;
}

@media only screen and (max-width: 600px) {
    .install-padding{
        padding: 16px;
    }
    .clientContainer{
        padding-top: 25px;
    }
    .home-clientsContainer{
        padding: 10px;
    }
    .home-clients-img {
        height: 60px;
        padding: 5px;
    }
    .home-clients-img img{
        width: 75%;
    }
}