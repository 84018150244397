/* .bottomNav__ul li{
    display: inline;
} */

.no-underline-onHover:hover{
    text-decoration: none;
}

.blue-onHover:hover{
    /* color: #262476; */
    color: #60a5fa;
}

.blue-onHover:focus{
    color: #60a5fa !important;
}
.is-active {
    color: #60a5fa !important;
}

.about-list{
    transition: all 300ms linear 0ms;
}

#nav-dropdown-dark-example{
    padding: 0px;
    color: #4B5563;
}

.dropdown-toggle::after{
    display: inline-flex !important;
    border: none !important;
    padding: 8px;
    background: url("../../assets/images/blue-ar.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.dropdown-menu{
    margin-top: 5px !important;
    padding: 0px !important;
}

.navbar-toggler {
    padding: 2px 8px !important;
    font-size: 1rem !important;
}

.download-pdf-btn{
    border-radius: 0px !important;
    border: transparent !important;
}

.download-pdf-btn::after{
    display: none !important;
}

.download-pdf-btn:focus{
    box-shadow: none !important;
}

.brochure{
    font-weight: bold !important;
}

.brochure:hover{
    color: #fff !important;
    background-color: rgba(49, 46, 129, 1) !important;
    transition: all 300ms linear 0ms;
}

@media screen and (max-width: 786px) {
    .header{
        display: none;
    }
}

@media screen and (min-width: 787px) {
    .header-mobile{
        display: none;
    }
}

@media screen and (min-width: 786px) {
#responsive-navbar-nav{
    display: block !important;
}
}