.team-list {
    justify-content: center;
    padding: 10px 0px !important;
}

.team-list a{
    width: auto;
    color: #fff !important;
    background-color: #6c757d !important;
    padding: 15px !important;
    margin: 1px;
    border-radius: 0% !important;
    font-weight: 500 !important;
    text-align: center;
    cursor: pointer;
}

.team-navigation.active{
    background: #262476 !important;
}

.team-content{
    position: relative;
}


@media only screen and (max-width: 600px) {
    .team-list a{
        font-size: 15px;
        padding: 12px !important;
    }
    .team-list {
        padding: 0px !important;
    }
    .team-thought{
        margin-top: 48px;
    }
    .team-experience p{
        text-align: justify;
    }
}