.submitform:hover {
  background-color: white !important;
  color: var(--darkBlue) !important;
  text-decoration: none;
}
.submitform{
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.add-logo{
  width: 100% !important;
  height: auto !important;
}



@media screen and (min-width: 787px) {
  .contactContainer-mobile{
      display: none;
  }
  .contact-headtext-mobile{
    display: none;
  }
  .contact-addr-mobile{
    display: none;
  }
}

@media screen and (max-width: 786px) {
  /* .contactContainer{
      display: none;
  } */
  .contact-headtext{
    display: none;
  }
  .contact-addr{
    display: none;
  }
  .contact-addr-mobile{
    display: inline-block;
  }
  .add-logo{
    height: 40px !important;
    width: 40px !important;
    margin-top: -20px;
  }
}