.researchbackdrop{
    background-image: url("../../assets/images/careers/handshake.png");
    background-position: 108% bottom;
    background-repeat: no-repeat;
    background-size: inherit;
}



@media screen and (min-width: 787px) {
    .researchbackdrop-mobile{
        display: none;
    }
}
  
@media screen and (max-width: 786px) {
    .researchbackdrop{
        display: none;
    }
    .researchbackdrop-mobile{
        background-image: url("../../assets/images/careers/handshake.png");
        background-position: center 100%;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}