.careerbackdrop{
    background-image: url("../../assets/images/careers/handshake.png");
    background-position: -8% bottom;
    background-repeat: no-repeat;
    background-size: inherit;
}

.grid-container-gallery {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 30px;
}

.grid-item-gallery{
    height: 150px;
    border: 2px solid lightgray;
    padding: 15px;
}

.common-image-size{
    width: 80%;
    margin: 0px auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#cus-width1{
    width: 60%;
}

#cus-width2{
    width: 100%;
}


@media only screen and (max-width: 600px) {
    .grid-container-gallery {
        grid-gap: 10px;
        grid-template-columns: auto auto auto;
        margin-top: 10px;
    }
    .grid-item-gallery{
        height: 80px;
        padding: 2px;
    }
    
    .common-image-size{
        width: 75%;
    }

    #cus-width1{
        width: 50%;
    }
    
    #cus-width2{
        width: 90%;
    }
}