.html {
  height: 100%;
}

.innerBanner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 20rem;
}

@media only screen and (max-width: 600px) {
  .innerBanner {
    display: none;
  }
}