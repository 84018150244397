.testBG{
    background-image: url("../../assets/images/testi.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.cusArrTest .slick-prev,
.cusArrTest .slick-next{
    display: block !important;
    bottom: -60px !important;
    top: inherit !important;
    left: 0 ;
    width: 2rem !important;
}

.cusArrTest .slick-next{
    left: 40px !important;
}

.clientsays-text{
    margin-bottom: 48px;
}

.home-testimony{
    padding-left: 48px;
}

.hometestimony-text{
    margin-top: 8px;
}

.home-testimony-slider>.slick-prev{
    top: 0% !important;
    left: 90% !important;
    z-index: 1;
}

.home-testimony-slider>.slick-next{
    top: 0% !important;
    left: 95% !important;
    z-index: 1;
}


@media only screen and (max-width: 600px) {
    .clientsays-text{
        margin-bottom: 0px;
    }
    .home-testimony{
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 30px;
    }
    .hometestimony-text{
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .hometestimony-text p{
        text-align: justify;
    }
    .cusArrTest, .cusArrNext{
        bottom: -10px !important;
    }
    .home-testimony-slider>.slick-prev{
        left: 83% !important;
    }
}