@media screen and (min-width: 787px) {
    .turnkeyContainer-mobile{
        display: none;
    }
}
  
@media screen and (max-width: 786px) {
    .turnkeyContainer{
        display: none;
    }
}