.bannerCar .carousel-inner{
    height: 32rem;
}
.heroBannerImage{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.home-banner-carousel>.carousel-control-prev>.carousel-control-prev-icon{
    background-image: url(../../assets/images/banner-arrL.png) !important;
}

.home-banner-carousel>.carousel-control-next>.carousel-control-next-icon{
    background-image: url(../../assets/images/banner-arrR.png) !important;
}

.home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
    opacity: 1 !important;
    background-color: transparent !important;
    top: 281px !important;
    bottom: unset !important;
    position: absolute !important;
}

.home-banner-carousel>.carousel-control-prev{
    left: -20% !important;
}

.home-banner-carousel>.carousel-control-prev>.carousel-control-prev-icon{
    position: relative;
    top: 0px;
    width: 15px !important;
    height: 15px !important;
}

.home-banner-carousel>.carousel-control-next{
    left: -10% !important;
}

.home-banner-carousel>.carousel-control-next>.carousel-control-next-icon{
    position: relative;
    top: 0px;
    width: 15px !important;
    height: 15px !important;
}

.serverArr{
    left: 20px !important;
    z-index: 1;
}
.serverArrNext{
    right: 20px !important;
}

.weServe::after{
    content: "";
    width: 100px;
    height: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background-color: #F9BF00;
    position: absolute;
    right: 0;
}
.containerServe{
    position: relative;
    transform: translateY(-54px);
    -webkit-transform: translateY(-54px);
    -moz-transform: translateY(-54px);
    -ms-transform: translateY(-54px);
    -o-transform: translateY(-54px);
}

.carousel-control-prev,
.carousel-control-next{
    height: 10px !important;
    width: 2rem !important;
    bottom: 146px !important;
    top: inherit !important;
}

.carousel-control-prev{
    left: 8rem !important;
}
.carousel-control-next{
    left: 11rem !important;
}
.carArrCus{
    transform: translateY(-3rem);
}
.service-item{
    padding-top: 16px;
    padding-bottom: 16px;
}
.service-item>div>img{
    width: 64px;
    margin-right: 8px;
}
.service-text{
    font-size: 14px;
    margin-bottom: 0px;
}
.hero-section{
    height: 520px;
}

.banner-img1{
    float: right;
    width: 530px;
    margin-top: 15px;
}

.banner-img2{
    float: right;
    width: 340px;
    margin-right: 80px;
}
.banner-img3{
    float: right;
    width: 430px;
    margin-right: 50px;
}
.banner-img4{
    float: right;
    width: 410px;
    margin-right: 30px;
}

.home-banner-carousel>.carousel-inner>.carousel-item {
    /* transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out !important; */
    -webkit-transition: opacity 1s ease-in-out !important;
    -moz-transition: opacity 1s ease-in-out !important;
    -ms-transition: opacity 1s ease-in-out !important;
    -o-transition: opacity 1s ease-in-out !important;
    transition: opacity 1s ease-in-out !important;
}

.banner-mobile{
    display: none;
}

.banner-desktop{
    display: block;
}


.play-btn2{
    background-color: #262476;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    margin-left: 1rem;
    border: 2px solid #262476;
    padding: 0px 6px;
}

.play-btn2 img{
    display: inline;
    padding-left: 3px;
}


@media only screen and (max-width: 600px) {
    .bannerCar .carousel-inner{
        height: 16rem;
    }
    .serverArr{
        left: 2px !important;
    }
    .serverArrNext{
        right: 2px !important;
    }
    .service-item{
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    .service-item>div>img{
        width: 35px;
        margin-right: 2px;
    }
    .service-text{
        font-size: 10px;
    }
    .hero-section{
        height: 275px;
    }
    .banner-mobile{
        display: block;
    }
    .banner-desktop{
        display: none;
    }
    .containerServe{
        position: relative;
        transform: translateY(-24px);
        -webkit-transform: translateY(-24px);
        -moz-transform: translateY(-24px);
        -ms-transform: translateY(-24px);
        -o-transform: translateY(-24px);
    }
    .banner-mobile{
        background-position: right;
    }
    .banner-img1{
        position: absolute;
        float: right;
        width: 245px;
        margin-right: unset;
        right: 35px;
        top: 55px;
    }
    
    .banner-img2{
        position: absolute;
        float: right;
        width: 160px;
        margin-right: unset;
        right: 80px;
        top: 60px;
    }
    .banner-img3{
        position: absolute;
        width: 200px;
        margin-right: unset;
        right: 65px;
        top: 60px;
    }
    .banner-img4{
        position: absolute;
        float: right;
        width: 190px;
        margin-right: unset;
        right: 50px;
        top: 60px;
    }
    .banner-caption{
        text-align: center;
    }
    .home-banner-carousel>.carousel-indicators{
        display: none;
    }
    .home-banner-carousel>.carousel-control-prev{
        left: 45% !important;
    }
    .home-banner-carousel>.carousel-control-next{
        left: 58% !important;
    }
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 210px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1300px) { 
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 265px !important;
    }
    .home-banner-carousel>.carousel-control-prev {
        left: -19% !important;
    }
}

@media only screen and (min-width: 1301px) and (max-width: 1400px) { 
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 271px !important;
    }
    .home-banner-carousel>.carousel-control-prev {
        left: -19% !important;
    }
}

@media only screen and (min-width: 1401px) and (max-width: 1599px) { 
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 281px !important;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1750px) { 
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 292px !important;
    }
    .home-banner-carousel>.carousel-control-prev {
        left: -22% !important;
    }
}

@media only screen and (min-width: 1751px) and (max-width: 1900px) { 
    .home-banner-carousel>.carousel-control-prev , .home-banner-carousel>.carousel-control-next{
        top: 299px !important;
    }
    .home-banner-carousel>.carousel-control-prev {
        left: -22% !important;
    }
}