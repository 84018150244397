.missionContainer{
    max-width: 100% !important;
    margin-bottom: -24px !important;
}

.mission-left-bg{
    background-image: url("../../assets/images/about/mission-left.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
}

.mission-left-text{
    background-color: #262476; 
}

.mission-left-text p{
    color: #fff;
}

.mission-right-bg{
    background-image: url("../../assets/images/about/vision.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
}

.mission-content{
    padding: 64px 48px;
}


@media only screen and (max-width: 600px) {
    .mission-left-bg{
        height: 300px;
        background-position: left; 
    }
    .mission-right-bg{
        height: 300px;
    }
    .mission-content{
        padding: 16px 12px;
    }
    .mission-content p{
        text-align: justify;
    }        
}