.newsInput{
    border: 1px solid white;
}

.newsInput::placeholder{
    color: white;
}

@media screen and (max-width: 786px) {
    .footer{
        display: none;
    }
}

@media screen and (min-width: 787px) {
    .footer-mobile{
        display: none;
    }
}