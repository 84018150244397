.careerbackdrop{
    background-image: url("../../assets/images/careers/handshake.png");
    background-position: -8% bottom;
    background-repeat: no-repeat;
    background-size: inherit;
}

@media screen and (min-width: 787px) {
    .careerbackdrop-mobile{
        display: none;
    }
}

@media screen and (max-width: 786px) {
    .careerbackdrop{
        display: none;
    }
    .careerbackdrop-mobile{
        background-image: url("../../assets/images/careers/handshake.png");
        background-position: center 102%;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}